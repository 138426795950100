import React from 'react';
import { Link } from 'gatsby';
import styled from 'react-emotion';
import logo from '../assets/logo-symbol.svg';

const Wrapper = styled('div')`
  padding: ${props => (props.isHomePage ? '2em' : '')};
`;

const linkStyles = `
  @media (max-width: 720px) {
    display: none;
  }
  color: #fff;
  text-decoration: none;
  letter-spacing: 1px;
  margin: 0,
  text-align: center;
  font-size: 20px;
  &:hover, &:active, &:focus {
    text-decoration: none;
  }
`;

const Navbar = ({ isHomePage, projects }) => {
  let styles = ``;
  if (!isHomePage) {
    styles = `
      display: flex;
      align-items: center;
    `;
  }
  return (
    <Wrapper css={styles}>
      {!isHomePage && (
        <Branding>
          <img
            src={logo}
            height="30"
            width="30"
            css={`
              margin-bottom: 0;
              margin-right: 1em;
            `}
            alt="Kimmel Logo"
          />
          <Link to="/" css={linkStyles}>
            Kimmel Changelog
          </Link>
        </Branding>
      )}
      <Nav isHomePage={isHomePage}>
        <NavItems isHomePage={isHomePage}>
          {projects.map(({ node: project }) => (
            <Link key={project.id} to={`/${project.slug}/`} css={linkStyles}>
              <NavItem>{project.name}</NavItem>
            </Link>
          ))}
        </NavItems>
      </Nav>
    </Wrapper>
  );
};

const Branding = styled('div')`
  flex: 2;
  display: flex;
  align-items: center;
  padding-left: 2em;
`;

const Nav = styled('nav')(props => !props.isHomePage && { flex: 3 });

const NavItems = styled('ul')`
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: ${props => (props.isHomePage ? 'center' : 'flex-end')};
  margin: 0;
`;

const NavItem = styled('li')`
  padding: 10px;
  margin-right: 10px;
  margin-left: 10px;
  margin-bottom: 0;
  color: #fff;
  letter-spacing: 0.5px;
  border-bottom: 2px solid transparent;

  &:hover {
    border-bottom: 2px solid #fff;
  }
`;

export default Navbar;
