import React from 'react';
import Navbar from './navbar';
import logo from '../assets/logo-symbol.svg';

const Header = ({ pathname, projects }) => {
  const isHomePage = pathname === '/';
  return (
    <div
      css={`
        background: #42275a;
        background: -webkit-linear-gradient(to right, #42275a, #734b6d);
        background: linear-gradient(to right, #42275a, #734b6d);
        margin-bottom: 1.45rem;
      `}
    >
      {isHomePage ? (
        <div
          css={`
            height: 100vh;
            margin: 0 auto;
            padding: 1.45rem 1.0875rem;
            display: flex;
            flex: 1;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          `}
        >
          <img src={logo} height="115" width="115" alt="Kimmel Logo" />
          <h1
            css={`
              margin: 0;
              text-align: center;
              color: #fff;
              letter-spacing: 1px;
              margin-bottom: 50px;
            `}
          >
            Kimmel Systems Changelog
          </h1>
          <div
            css={`
              width: 90%;
              max-width: 1200px;
            `}
          >
            <h2
              css={`
                color: #fff;
                text-align: center;
                &:before,
                &:after {
                  background-color: #fff;
                  content: '';
                  display: inline-block;
                  height: 1px;
                  position: relative;
                  vertical-align: middle;
                  width: 10%;
                }
                &:before {
                  right: 0.5em;
                  margin-left: -50%;
                }
                &:after {
                  left: 0.5em;
                  margin-right: -50%;
                }
              `}
            >
              Projects
            </h2>
            <Navbar projects={projects} isHomePage={isHomePage} />
          </div>
        </div>
      ) : (
        <Navbar projects={projects} isHomePage={isHomePage} />
      )}
    </div>
  );
};

export default Header;
