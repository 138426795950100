import React from 'react';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';
import Header from '../components/header';
import './layout.css';

const Layout = ({ children, location }) => (
  <StaticQuery
    query={graphql`
      query SiteData {
        site {
          siteMetadata {
            title
            description
            keywords
          }
        }
        allContentfulProject {
          edges {
            node {
              id
              name
              slug
            }
          }
        }
      }
    `}
    render={data => (
      <div
        css={`
          background-color: #fff;
        `}
      >
        <Helmet
          title={data.site.siteMetadata.title}
          meta={[
            {
              name: 'description',
              content: data.site.siteMetadata.description,
            },
            {
              name: 'keywords',
              content: data.site.siteMetadata.keywords,
            },
          ]}
        />
        <Header
          projects={data.allContentfulProject.edges}
          pathname={location.pathname}
        />
        <div
          style={{
            margin: '0 auto',
            padding: '0px 1.45rem 1.45rem',
            paddingTop: 0,
          }}
        >
          {children}
        </div>
      </div>
    )}
  />
);

export default Layout;
